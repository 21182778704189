import React, { useContext } from 'react';

import { MockRuleForm, MockRuleList } from '../index';
import SpaceContext from '../../context';

const MockRule = () => {
  const contextData = useContext(SpaceContext);
  const { mockRuleForm, MockRules } = contextData;

  const handleRuleSave = (mockRule) => {
    if(!mockRule.ID) {
      contextData.addMockRule(mockRule);
    } else {
      contextData.updateMockRule(mockRule);
    }
  }

  return (
    <div>
      <MockRuleForm mockRule={mockRuleForm} onRuleSave={handleRuleSave} />
      <MockRuleList mockRules={MockRules} />
    </div>
  )
};

export default MockRule;