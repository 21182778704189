import DataService from "./DataService";

class MockRuleService extends DataService {

  static async getAll(spaceId) {
    const apiHost = this._getBackendHost();
    const proto = process.env.REACT_APP_SECURE_SERVER === 'true' ? 'https' : 'http'; 
    let url;

    if(spaceId) {
      url = `${proto}://core.${apiHost}/core/v1/spaces/${spaceId}/mockrules`;
    } else {
      url = `${proto}://core.${apiHost}/core/v1/mockrules`;
    }

    const response = await this.GET(url);

    if(response.Success && response.Data) {
      return response.Data;
    } else {
      return [];
    }
  }

  static async create(spaceId, mockRule) {
    const apiHost = this._getBackendHost();
    const proto = process.env.REACT_APP_SECURE_SERVER === 'true' ? 'https' : 'http'; 
    const url = `${proto}://core.${apiHost}/core/v1/spaces/${spaceId}/mockrules`;

    const response = await this.POST(url, mockRule);

    return response;
  }

  static async update(spaceId, mockRule) {
    const apiHost = this._getBackendHost();
    const proto = process.env.REACT_APP_SECURE_SERVER === 'true' ? 'https' : 'http'; 
    const url = `${proto}://core.${apiHost}/core/v1/spaces/${spaceId}/mockrules/${mockRule.ID}`;

    const response = await this.PUT(url, mockRule);

    return response;
  }

  static async delete(spaceId, mockRule) {
    const apiHost = this._getBackendHost();
    const proto = process.env.REACT_APP_SECURE_SERVER === 'true' ? 'https' : 'http'; 
    const url = `${proto}://core.${apiHost}/core/v1/spaces/${spaceId}/mockrules/${mockRule.ID}`;

    const response = await this.DELETE(url);

    return response;
  }
}

export default MockRuleService;