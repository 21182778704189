import React, { useContext } from 'react';
import { Collapse, Tag, PageHeader, Button, Descriptions, 
  Card, Col, Row, Input, Popover, Tooltip,
  Space as AntSpace } from 'antd';

import SpaceContext from '../../context';

const { Panel } = Collapse;
const { TextArea } = Input;


const SpaceRequestList = ({ requests }) => {
  const contextData = useContext(SpaceContext);

  const getRequestActions = (request) => (
    [
      <Button key="save" type="dashed" onClick={(event) => createMock(event, request)}>Save It</Button>,
      <Button key="mock" type="dashed" onClick={(event) => createMock(event, request)}>Mock It</Button>,
    ]
  );

  const createMock = (event, request) => {
    event.stopPropagation();
    console.log('request for mock', request);

    contextData.setActiveTab('mock_rules');

    const mockRule = {
      SpaceID: contextData.spaceId,
      Name: `Mock for ${request.RequestResponse.Request.Path}`,
      RequestMethod: request.RequestResponse.Request.Method,
      RequestPath: request.RequestResponse.Request.Path,
      ResponseStatusCode: request.RequestResponse.Response.StatusCode,
      ResponseHeaders: request.RequestResponse.Response.Headers,
      ResponseBody: request.RequestResponse.Response.Body,
      Active: true,
    }

    if(mockRule.ResponseHeaders) {
      mockRule.ResponseHeaders = JSON.stringify(mockRule.ResponseHeaders);
    }

    contextData.setMockRuleForm(mockRule);
  }

  const genRequestHeader = (request) => {
    if(request.RequestResponse) {    
      return (
        <span>
          <Tag color="blue">{request.RequestResponse.Request.Method}</Tag>
          { request.RequestResponse.Request.Path }
        </span>
      );
    }
  }

  const genSubActions = (request, type) => {
    const actions = [];

    actions.push(
      <Popover key="headers" content={<TextArea value={extractData(request, type, 'Headers')} autoSize={{ minRows: 3, maxRows: 6}}/>}>
        <Button type="primary" ghost={true}>Headers</Button>
      </Popover>
    );

    if(type === 'Request') {
      actions.unshift(
        <Popover key="query_params" content={<TextArea value={extractData(request, type, 'QueryParams')} autoSize={{ minRows: 3, maxRows: 6}}/>}>
          <Button type="primary" ghost={true}>Query Params</Button>
        </Popover>
      );
    }
    return (<AntSpace>{actions}</AntSpace>);
  }

  const extractData = (request, type, field) => {
    // type: Request | Response
    // field: Body | Headers
    if (request.RequestResponse) {
     return JSON.stringify(request.RequestResponse[type][field], null, '\t');
    }
  }

    return (
      <div>
        <Collapse bordered={false} key="collapse">
          { requests.map((request, index) => {
            return (
              <Panel header={genRequestHeader(request)} key={index} extra={getRequestActions(request)}>
                <Row>
                  <PageHeader>
                    <Descriptions size="small" column={3}>
                      <Descriptions.Item label="Path" span={3}>
                        {request.RequestResponse && request.RequestResponse.Request.Path}
                      </Descriptions.Item>
                      <Descriptions.Item label="Method">
                        <Tag color="blue">
                          {request.RequestResponse && request.RequestResponse.Request.Method}
                        </Tag>
                      </Descriptions.Item>
                      <Descriptions.Item label="Protocol">{request.RequestResponse && request.RequestResponse.Request.Protocol}</Descriptions.Item>
                      <Descriptions.Item label="Response Status">{request.RequestResponse && request.RequestResponse.Response.StatusCode}</Descriptions.Item>
                    </Descriptions>
                  </PageHeader>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <Card title="Request" bordered={false} extra={genSubActions(request, 'Request')}>
                      <TextArea disabled={true} value={extractData(request, 'Request', 'Body')} autoSize={{ minRows: 5, maxRows: 10 }}/>
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card title="Response" bordered={false} extra={genSubActions(request, 'Response')}>
                      <TextArea disabled={true} value={extractData(request, 'Response', 'Body')} autoSize={{ minRows: 5, maxRows: 10 }}/>
                    </Card>
                  </Col>
                </Row>
              </Panel>
            )
          })}
        </Collapse>
      </div>
    );
}

export default SpaceRequestList;