import React, { useContext } from 'react';
import { Table, Popconfirm, Form, Switch, Tag, Row, Col, Card, Input,
  Space as AntSpace, Button, Anchor } from 'antd';
import { Typography } from 'antd';
import { EditOutlined, DeleteOutlined, CloseOutlined, EyeOutlined } from '@ant-design/icons';

import SpaceContext from '../../context';

const { Title, Paragraph } = Typography;


const MockRuleList = ({ mockRules }) => {
  const contextData = useContext(SpaceContext);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'Name',
      width: '15%',
    },
    {
      title: 'Method',
      dataIndex: "RequestMethod",
      width: '5%',
      render: (_, mockRule) => {
        return (
          <span>
            <Tag color="blue">{mockRule.RequestMethod}</Tag>
          </span>
        );
      },
    },
    {
      title: 'Request URL Path',
      dataIndex: "RequestPath",
      width: '50%',
      render: (_, mockRule) => {
        return (
          <span>
            {mockRule.RequestPath}
          </span>
        );
      },
    },
    {
      title: 'Status Code',
      dataIndex: "ResponseStatusCode",
      width: '10%',
    },
    {
      title: 'Created At',
      dataIndex: "CreatedAt",
      width: '10%',
      render: (_, mockRule) => {
        return (
          <span>
            { new Date(mockRule.CreatedAt).toLocaleString() }
          </span>
        );
      },
    },
    {
      title: 'Active',
      dataIndex: 'Active',
      render: (_, mockRule) => {
        return (
          <span>
            <Switch defaultChecked={mockRule.Active} onChange={(checked) => handleActiveToggle(checked, mockRule)} />
          </span>
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'Actions',
      render: (_, mockRule) => {
        return (
          <span>
            <AntSpace>
              <Button onClick={() => prepareForEdit(mockRule)} href="#MockRuleForm"><EditOutlined key="edit" />Edit</Button>

              <Popconfirm title="Confirm Delete?" placement="left"
                onConfirm={() => handleDelete(mockRule)}>
                  <Button danger><DeleteOutlined key="delete" />Delete</Button>
              </Popconfirm>
            </AntSpace>
          </span>
        );
      },
    },
  ];

  const handleActiveToggle = (checked, mockRule) => {
    mockRule.Active = checked;
    contextData.updateMockRule(mockRule);
  }

  const handleDelete = (mockRule) => {
    contextData.deleteMockRule(mockRule);
  }

  const prepareForEdit = (mockRule) => {
    contextData.setMockRuleForm(mockRule);
  }

  const rowExpandable = {
    expandedRowRender: (mockRule) => {
      return (
        <span>
          
          <Row gutter={24}>
            <Col span={8}>
              <Card title="Response Headers">
                {/* <TextArea value={mockRule.ResponseHeaders} autoSize={{ minRows: 5, maxRows: 10 }}/> */}
                <Paragraph editable={{ onChange: (content) => contextData.updateMockRule({ ...mockRule, ResponseHeaders: content }) }}>
                  {mockRule.ResponseHeaders}
                </Paragraph>
              </Card>
            </Col>
            <Col span={16}>
              <Card title="Response Body">
                {/* <TextArea value={mockRule.ResponseBody} autoSize={{ minRows: 5, maxRows: 10 }}/> */}
                {/* <Text code>{JSON.stringify(mockRule.ResponseBody, null, '&nbsp;&nbsp;')}</Text> */}
                {/* <pre>{mockRule.ResponseHeaders}</pre> */}
                <Paragraph editable={{ onChange: (content) => contextData.updateMockRule({ ...mockRule, ResponseBody: content }) }}>
                  {mockRule.ResponseBody}
                </Paragraph>
              </Card>
            </Col>
          </Row>
        </span>
      )
    },
    expandIcon: ({ expanded, onExpand, record }) => {
      return (
        expanded ? (
          <Button type="dashed" onClick={e => onExpand(record, e)}><CloseOutlined />Close</Button>
        ) : (
          <Button type="primary" onClick={e => onExpand(record, e)}><EyeOutlined />View</Button>
        )
      )
    },
  };

  const genExpandedRowActions = (mockRule) => {
    return [
      <Button type="link" onClick={() => prepareForEdit(mockRule)}><EditOutlined key="edit" />Edit</Button>,
      <Popconfirm title="Confirm Delete?" placement="top"
        onConfirm={() => handleDelete(mockRule)}>
        <Button type="link"><DeleteOutlined key="delete" />Delete</Button>
      </Popconfirm> 
    ];
  }

  return (
    <Form component={false}>
      <Table
        title={() => <Title level={4}>Mock Rules</Title>}
        expandable={rowExpandable}
        rowKey="ID"
        // expandRowByClick={true}
        expandIconColumnIndex={6}
        dataSource={mockRules}
        columns={columns}
      />
    </Form>
  );
};

export default MockRuleList;