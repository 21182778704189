import DataService from "./DataService";

class SpaceService extends DataService {

  static connect(spaceId) {
    const apiHost = this._getBackendHost();
    const proto = process.env.REACT_APP_SECURE_SERVER === 'true' ? 'wss' : 'ws'; 
    const url = `${proto}://core.${apiHost}/core/v1/spaces/${spaceId}/inspect?proto=ws`;

    const wsConnection = new WebSocket(url);

    return wsConnection;
  }

  static async create(spaceId, spaceData) {
    const apiHost = this._getBackendHost();
    const proto = process.env.REACT_APP_SECURE_SERVER === 'true' ? 'https' : 'http'; 
    const url = `${proto}://core.${apiHost}/core/v1/spaces`;

    const spacePayload = {
      ID: spaceId
    };

    const response = await this.POST(url, spacePayload);

    if(response.Success) {
      return response.Data;
    } else {
      return {};
    }
  }

  static async update(spaceId, data) {
    const apiHost = this._getBackendHost();
    const proto = process.env.REACT_APP_SECURE_SERVER === 'true' ? 'https' : 'http'; 
    const url = `${proto}://core.${apiHost}/core/v1/spaces/${spaceId}`;

    const spacePayload = {
      ID: spaceId,
      Mocking: data.Mocking,
      Proxying: data.Proxying,
      Tampering: data.Tampering,
      ProxyURL: data.ProxyURL,
      MockRules: data.MockRules, // Needed to pass because no partial update is happening
    };

    const response = await this.PUT(url, spacePayload);

    if(response.Success) {
      return response.Data;
    } else {
      return {};
    }
  }
}

export default SpaceService;