import React, { useReducer } from 'react';

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Layout, Menu } from 'antd';

import './App.css';
import { Space } from './components';
import SpaceContext from './context';
import { MockRuleService, SpaceService } from './services';


const { Provider } = SpaceContext;
const { Header, Footer, Content } = Layout;

const defaultSpaceState = {
  spaceId: 'viz',
  status: 'DISCONNECTED',
  activeSpaceTab: 'requests', // requests | mock_rules
  mockRuleForm: {},
  MockRules: []
};

// TODO: Move this actions and reducer
// TODO: Segregate Actions and Events
const actions = {
  // Global Actions
  SET_SPACE_STATE: 'SET_SPACE_STATE',
  SET_ACTIVE_SPACE_TAB: 'SET_ACTIVE_SPACE_TAB',

  // Actions related to Space
  UPDATE_SPACE: 'UPDATE_SPACE',

  // Events related to Space
  SPACE_UPDATED: 'SPACE_UPDATED',
  
  // Actions related to Mock Rule Form
  SET_MOCK_RULE_FORM: 'SET_MOCK_RULE_FORM',
  ADD_MOCK_RULE: 'ADD_MOCK_RULE',
  UPDATE_MOCK_RULE: 'UPDATE_MOCK_RULE',
  DELETE_MOCK_RULE: 'DELETE_MOCK_RULE',

  // Events related to Mock Rule
  MOCK_RULE_ADDED: 'MOCK_RULE_ADDED',
  MOCK_RULE_UPDATED: 'MOCK_RULE_UPDATED',
  MOCK_RULE_DELETED: 'MOCK_RULE_DELETED',
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_SPACE_STATE:
      console.log('Action: SET_SPACE_STATE');
      return { ...state, ...action.payload };

    case actions.SET_ACTIVE_SPACE_TAB:
      console.log('Action: SET_ACTIVE_SPACE_TAB');
      return { ...state, activeSpaceTab: action.payload };

    // Space Actions
    case actions.UPDATE_SPACE:
      console.log('Action: UPDATE_SPACE');
      // No change in state, just make API Call
      const space = action.payload;
      SpaceService.update(state.spaceId, space);
      return state;

    // Space Events
    case actions.SPACE_UPDATED:
      console.log('Action: SPACE_UPDATED');
      const updatedSpace = action.payload;

      return { 
        ...state, 
        Mocking: updatedSpace.Mocking,
        Proxying: updatedSpace.Proxying,
        Tampering: updatedSpace.Tampering,
        ProxyURL: updatedSpace.ProxyURL,
      };

    // Mock Rule Actions
    case actions.SET_MOCK_RULE_FORM:
      console.log('Action: SET_MOCK_RULE_FORM');
      return { ...state, mockRuleForm: action.payload };

    case actions.ADD_MOCK_RULE:
      console.log('Action: ADD_MOCK_RULE');
      // No change in state, just make API Call
      const mockRule = action.payload;
      mockRule.SpaceID = state.spaceId;
      MockRuleService.create(state.spaceId, mockRule);
      return state;

    case actions.UPDATE_MOCK_RULE:
      console.log('Action: UPDATE_MOCK_RULE');
      // No change in state, just make API Call
      MockRuleService.update(state.spaceId, action.payload);
      return state;

    case actions.DELETE_MOCK_RULE:
      console.log('Action: DELETE_MOCK_RULE');
      // No change in state, just make API Call
      MockRuleService.delete(state.spaceId, action.payload);
      return state;
    
    // TODO: Mock Rule Events
    case actions.MOCK_RULE_ADDED:
      console.log('Action: MOCK_RULE_ADDED');
      return { ...state, MockRules: [ ...state.MockRules, action.payload ] };

    case actions.MOCK_RULE_UPDATED:
      console.log('Action: MOCK_RULE_UPDATED');

      const updatedMockRule = action.payload;
      const updatedMockRules = state.MockRules.map(mockRule => (mockRule.ID === updatedMockRule.ID ? updatedMockRule : mockRule ))

      return { ...state, MockRules: updatedMockRules };

    case actions.MOCK_RULE_DELETED:
      console.log('Action: MOCK_RULE_DELETED');
      const deletedMockRuleID = action.payload;
      const deletedMockRules = state.MockRules.filter(mockRule => (mockRule.ID !== deletedMockRuleID))

      return { ...state, MockRules: deletedMockRules };
  
    default:
      console.log('default action');
  }
};

const App = () => {
  const [ state, dispatch ] = useReducer(reducer, defaultSpaceState)

  const spaceState = {
    ...state,
    setSpaceState: (state) => (dispatch({ type: actions.SET_SPACE_STATE, payload: state })),
    setActiveTab: (tabKey) => (dispatch({ type: actions.SET_ACTIVE_SPACE_TAB, payload: tabKey })),

    // Action Method for Space
    updateSpace: (space) => (dispatch({ type: actions.UPDATE_SPACE, payload: space })),

    // Events Method for Space
    spaceUpdated: (space) => (dispatch({ type: actions.SPACE_UPDATED, payload: space })),
    
    // Action Method for Mock Rule
    setMockRuleForm: (form) => (dispatch({ type: actions.SET_MOCK_RULE_FORM, payload: form })),
    addMockRule: (mockRule) => (dispatch({ type: actions.ADD_MOCK_RULE, payload: mockRule })),
    updateMockRule: (mockRule) => (dispatch({ type: actions.UPDATE_MOCK_RULE, payload: mockRule })),
    deleteMockRule: (mockRule) => (dispatch({ type: actions.DELETE_MOCK_RULE, payload: mockRule })),

    // Events Method for Mock Rule
    mockRuleAdded: (mockRule) => (dispatch({ type: actions.MOCK_RULE_ADDED, payload: mockRule })),
    mockRuleUpdated: (mockRule) => (dispatch({ type: actions.MOCK_RULE_UPDATED, payload: mockRule })),
    mockRuleDeleted: (mockRule) => (dispatch({ type: actions.MOCK_RULE_DELETED, payload: mockRule })),
  }

  return (
    <Provider value={spaceState}>
    <Layout>
      <Header className="header">
        <div className="logo" />
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
          <Menu.Item key="api_space">API Space</Menu.Item>
          <Menu.Item key="api_mock">API Mock</Menu.Item>
          <Menu.Item key="api_proxy">API Proxy</Menu.Item>
          <Menu.Item key="features">Features</Menu.Item>
          <Menu.Item key="docs">Docs</Menu.Item>
          <Menu.Item key="pricing">Pricing</Menu.Item>
        </Menu>
      </Header>

      <Content style={{ padding: '0 50px'}}>
        <div>
          <Router>
            <Switch>
              <Route path="/" exact component={Space} />
              <Route path="/:spaceId" component={Space} />
              {/* <Route path="/spaces/:spaceId" component={Space} /> */}
            </Switch>
          </Router>
        </div>
      </Content>

      <Footer style={{ textAlign: 'center' }}>
        Privacy Policy | Terms of Service | API Space © 2020
      </Footer>
    </Layout>
    </Provider>
  )
}

export default App;
