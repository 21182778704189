import React, { useEffect } from 'react';
import { Form, Input, InputNumber, Select, Switch, Button,
  Space as AntSpace } from 'antd';

const { TextArea } = Input;
const { Option } = Select;

const MockRuleForm = ({ mockRule, onRuleSave }) => {
  const [ form ] = Form.useForm();

  const httpMethods = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE', 'HEAD', 'OPTIONS', 'TRACE'];
  const httpStatusCodes = [
    {
      "key": "100",
      "value": "100 - Continue"
    },
    {
      "key": "101",
      "value": "101 - Switching Protocols"
    },
    {
      "key": "102",
      "value": "102 - Processing"
    },
    {
      "key": "200",
      "value": "200 - OK"
    },
    {
      "key": "201",
      "value": "201 - Created"
    },
    {
      "key": "202",
      "value": "202 - Accepted"
    },
    {
      "key": "203",
      "value": "203 - Non-authoritative Information"
    },
    {
      "key": "204",
      "value": "204 - No Content"
    },
    {
      "key": "205",
      "value": "205 - Reset Content"
    },
    {
      "key": "206",
      "value": "206 - Partial Content"
    },
    {
      "key": "207",
      "value": "207 - Multi-Status"
    },
    {
      "key": "208",
      "value": "208 - Already Reported"
    },
    {
      "key": "226",
      "value": "226 - IM Used"
    },
    {
      "key": "300",
      "value": "300 - Multiple Choices"
    },
    {
      "key": "301",
      "value": "301 - Moved Permanently"
    },
    {
      "key": "302",
      "value": "302 - Found"
    },
    {
      "key": "303",
      "value": "303 - See Other"
    },
    {
      "key": "304",
      "value": "304 - Not Modified"
    },
    {
      "key": "305",
      "value": "305 - Use Proxy"
    },
    {
      "key": "307",
      "value": "307 - Temporary Redirect"
    },
    {
      "key": "308",
      "value": "308 - Permanent Redirect"
    },
    {
      "key": "400",
      "value": "400 - Bad Request"
    },
    {
      "key": "401",
      "value": "401 - Unauthorized"
    },
    {
      "key": "402",
      "value": "402 - Payment Required"
    },
    {
      "key": "403",
      "value": "403 - Forbidden"
    },
    {
      "key": "404",
      "value": "404 - Not Found"
    },
    {
      "key": "405",
      "value": "405 - Method Not Allowed"
    },
    {
      "key": "406",
      "value": "406 - Not Acceptable"
    },
    {
      "key": "407",
      "value": "407 - Proxy Authentication Required"
    },
    {
      "key": "408",
      "value": "408 - Request Timeout"
    },
    {
      "key": "409",
      "value": "409 - Conflict"
    },
    {
      "key": "410",
      "value": "410 - Gone"
    },
    {
      "key": "411",
      "value": "411 - Length Required"
    },
    {
      "key": "412",
      "value": "412 - Precondition Failed"
    },
    {
      "key": "413",
      "value": "413 - Payload Too Large"
    },
    {
      "key": "414",
      "value": "414 - Request-URI Too Long"
    },
    {
      "key": "415",
      "value": "415 - Unsupported Media Type"
    },
    {
      "key": "416",
      "value": "416 - Requested Range Not Satisfiable"
    },
    {
      "key": "417",
      "value": "417 - Expectation Failed"
    },
    {
      "key": "418",
      "value": "418 - I'm a teapot"
    },
    {
      "key": "421",
      "value": "421 - Misdirected Request"
    },
    {
      "key": "422",
      "value": "422 - Unprocessable Entity"
    },
    {
      "key": "423",
      "value": "423 - Locked"
    },
    {
      "key": "424",
      "value": "424 - Failed Dependency"
    },
    {
      "key": "426",
      "value": "426 - Upgrade Required"
    },
    {
      "key": "428",
      "value": "428 - Precondition Required"
    },
    {
      "key": "429",
      "value": "429 - Too Many Requests"
    },
    {
      "key": "431",
      "value": "431 - Request Header Fields Too Large"
    },
    {
      "key": "444",
      "value": "444 - Connection Closed Without Response"
    },
    {
      "key": "451",
      "value": "451 - Unavailable For Legal Reasons"
    },
    {
      "key": "499",
      "value": "499 - Client Closed Request"
    },
    {
      "key": "500",
      "value": "500 - Internal Server Error"
    },
    {
      "key": "501",
      "value": "501 - Not Implemented"
    },
    {
      "key": "502",
      "value": "502 - Bad Gateway"
    },
    {
      "key": "503",
      "value": "503 - Service Unavailable"
    },
    {
      "key": "504",
      "value": "504 - Gateway Timeout"
    },
    {
      "key": "505",
      "value": "505 - HTTP Version Not Supported"
    },
    {
      "key": "506",
      "value": "506 - Variant Also Negotiates"
    },
    {
      "key": "507",
      "value": "507 - Insufficient Storage"
    },
    {
      "key": "508",
      "value": "508 - Loop Detected"
    },
    {
      "key": "510",
      "value": "510 - Not Extended"
    },
    {
      "key": "511",
      "value": "511 - Network Authentication Required"
    },
    {
      "key": "599",
      "value": "599 - Network Connect Timeout Error"
    }
  ];

  const initialFormValues = {
    RequestMethod: httpMethods[0],
    ResponseStatusCode: 200,
    Active: true,
  }

  useEffect(() => {
    fillForm(mockRule);
  }, [ mockRule ]);

  const onReset = () => {
    form.resetFields();
  };

  const fillForm = (data) => {
    if(data.Response && data.Response.Headers) {
      data.Response.Headers = JSON.stringify(data.Response.Headers);
    }
    form.setFieldsValue(data);
  };

  const onFinish = formData => {
    console.log('Received values of form: ', formData);
    if(formData.ResponseStatusCode) {
      formData.ResponseStatusCode = Number(formData.ResponseStatusCode);
    }
    onRuleSave(formData);
    form.resetFields();
  };


  return (

    <Form name="complex-form" form={form} onFinish={onFinish} initialValues={initialFormValues}
          labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} id="MockRuleForm">

      {/* mockRule { JSON.stringify(mockRule) } */}

      <Form.Item hidden label="Mock Rule ID">
        <Form.Item name="ID" noStyle>
          <Input style={{ width: '50%' }} placeholder="ID" />
        </Form.Item>
      </Form.Item>

      <Form.Item label="Rule Name">
        <Form.Item name="Name" noStyle>
          <Input style={{ width: '50%' }} placeholder="Enter Rule name" />
        </Form.Item>
      </Form.Item>

      <Form.Item label="Description">
        <Form.Item name="Description" noStyle>
          <TextArea style={{ width: '50%' }} placeholder="Enter Description" autoSize={{ minRows: 3, maxRows: 5}}/>
        </Form.Item>
      </Form.Item>

      <Form.Item label="Request">
        <Input.Group compact>
          <Form.Item name="RequestMethod" noStyle
            rules={[{ required: true, message: 'Method is required' }]}
          >
            <Select placeholder="HTTP Method" style={{ width: '10%' }}>
              { httpMethods.map(method => <Option value={method}>{method}</Option>) }
            </Select>
          </Form.Item>
          <Form.Item name="RequestPath" noStyle
            rules={[{ required: true, message: 'Path is required' }]}
          >
            <Input style={{ width: '40%' }} placeholder="Path"/>
          </Form.Item>
        </Input.Group>
      </Form.Item>

      <Form.Item label="Response Status Code">
        <Form.Item name="ResponseStatusCode" noStyle
          rules={[{ required: true, message: 'Status Code is required' }]}
        >
          {/* <InputNumber style={{ width: '20%' }} placeholder="Statue Code" min={100}/> */}
          <Select style={{ width: '50%' }} placeholder="HTTP Statue Code" showSearch>
              { httpStatusCodes.map(code => <Option value={code.key}>{code.value}</Option>) }
            </Select>
        </Form.Item>
      </Form.Item>

      <Form.Item label="Response Headers">
        <Form.Item name="ResponseHeaders" noStyle>
          <TextArea placeholder="Headers" style={{ width: '50%' }} autoSize={{ minRows: 3, maxRows: 6}} />
        </Form.Item>
      </Form.Item>

      <Form.Item label="Response Body">
        <Form.Item name="ResponseBody" noStyle
          rules={[{ required: true, message: 'Response Body is required' }]}
        >
          <TextArea placeholder="Body" style={{ width: '50%' }} autoSize={{ minRows: 4, maxRows: 8}} />
        </Form.Item>
      </Form.Item>

      <Form.Item label="Active" >
        <Form.Item name="Active" label="Active" noStyle valuePropName="checked"
          rules={[{ required: true, message: 'Active is required' }]}
        >
          <Switch checkedChildren="ACTIVE" unCheckedChildren="INACTIVE"/>
        </Form.Item>
      </Form.Item>

      <Form.Item label=" " colon={false}>

        <AntSpace>

          <Button type="primary" htmlType="submit" size="middle">
            Add Rule
          </Button>
          <Button type="dashed" htmlType="button" size="middle" onClick={onReset}>
            Reset
          </Button>
        </AntSpace>
      </Form.Item>
    </Form>
  )
};

export default MockRuleForm;