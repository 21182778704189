import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { PageHeader, Tabs, Switch, Button, Badge, Empty, Descriptions, 
  Input, Checkbox, Radio, Tooltip, Space as AntSpace, Result, message } from 'antd';
  import { Typography } from 'antd';
import { ApiOutlined, DeleteOutlined, ProfileOutlined,
  ExportOutlined, CloudSyncOutlined, RedoOutlined, SmileOutlined } from '@ant-design/icons';

import { SpaceRequestList, MockRule } from '../index';
import { SpaceService, MockRuleService, Simulator } from '../../services';
import SpaceContext from '../../context';

const { TabPane } = Tabs;
const { Search } = Input;
const { Paragraph } = Typography;

const DEFAULT_SPACE = {
  Mocking: false,
  Proxying: false,
  Tampering: false,
  MockingLoading: false,
  ProxyingLoading: false,
  TamperingLoading: false,
  ProxyURL: ''
};


// TODO: Get this from ENV.
const WEBSOCKET_RECONNECT_DURATION = 5 * 1000; // 5 seconds

const Space = () => {
  const contextData = useContext(SpaceContext);
  const history = useHistory();
  const urlRef = useRef();

  const { spaceId = contextData.spaceId } = useParams();
  const [ spaceData, setSpaceData ] = useState({ ...DEFAULT_SPACE, id: spaceId });
  const [ spaceRequests, setSpaceRequests ] = useState([]);

  useEffect(() => {
    // Clear the existing requests
    setSpaceRequests([]);

    // Syncing spaceId with context
    contextData.setSpaceState({ spaceId: spaceData.id });

    // Connecting with WebSocket to get Events
    const wsConnection = getSpaceRequests();

    (async function () {
      // Get or Create Space
      const spaceResponse = await SpaceService.create(spaceData.id);

      // Getting MockRules if already exists at Server
      const mockRulesResponse = await MockRuleService.getAll(spaceData.id);

      const data = {
        Mocking: spaceResponse.Mocking,
        Proxying: spaceResponse.Proxying,
        Tampering: spaceResponse.Tampering,
        ProxyURL: spaceResponse.ProxyURL,
        // MockRules: spaceResponse.MockRules,
      }

      // Syncing data with context
      contextData.setSpaceState({ ...data, MockRules: mockRulesResponse });

      // Syncing data with component's local state
      setSpaceData({ ...spaceData, ...data });
    })();

    return () => {
      console.log('Closing socket connection with space:', spaceData.id);
      wsConnection.onclose = () => {};
      wsConnection.close();
    }
    
  }, [ spaceData.id ]);

  const getSpaceRequests = () => {
    const wsConnection =  SpaceService.connect(spaceData.id);
    contextData.setSpaceState({status: 'CONNECTING'});

    wsConnection.onopen = () => {
      contextData.setSpaceState({status: 'CONNECTED'});
      message.success(`[${spaceData.id}] Connected to the Space`);
    }

    wsConnection.onmessage = (event) => {
      console.log('Received Message', event.data);
      const data = JSON.parse(event.data);

      if(data.MessageType === 'DATA') {
        setSpaceRequests(currentData => [...currentData, data]);
      } else if(data.MessageType === 'SPACE_UPDATED') {
        console.log('SPACE_UPDATED DATA', data);

        contextData.spaceUpdated(data.Space);

        const configData = {
          Mocking: data.Space.Mocking,
          Proxying: data.Space.Proxying,
          Tampering: data.Space.Tampering,
          ProxyURL: data.Space.ProxyURL,
        };

        const loadingFlags = {
          MockingLoading: false,
          ProxyingLoading: false,
          TamperingLoading: false,
        };

        setSpaceData({...spaceData, ...configData , ...loadingFlags});
        message.success(`[${spaceData.id}] Space config updated`);
      } else if(data.MessageType === 'MOCK_RULE') {
        console.log('MOCK_RULE DATA', data);

        contextData.mockRuleAdded(data.MockRule);
        message.success(`[${spaceData.id}] Mock Rule added for path: ${data.MockRule.RequestPath}`);
      } else if(data.MessageType === 'MOCK_RULE_UPDATED') {
        console.log('MOCK_RULE_UPDATED DATA', data);

        contextData.mockRuleUpdated(data.MockRule);
        message.success(`[${spaceData.id}] Mock Rule updated for path: ${data.MockRule.RequestPath}`);
      } else if(data.MessageType === 'MOCK_RULE_DELETED') {
        console.log('MOCK_RULE_DELETED DATA', data);

        contextData.mockRuleDeleted(data.MockRule.ID);
        message.success(`[${spaceData.id}] Mock Rule deleted for path: ${data.MockRule.RequestPath}`);
      } else {
        console.log('else DATA', data);
      }

    }

    wsConnection.onclose = (event) => {
      message.warning(`[${spaceData.id}] Connection closed with Space: ${JSON.stringify(event)}`);
      setTimeout(()=> { getSpaceRequests() }, WEBSOCKET_RECONNECT_DURATION);
    }

    wsConnection.onerror = (event) => {
      message.error(`[${spaceData.id}] Error in connection with Space: ${JSON.stringify(event)}`);
      contextData.setSpaceState({status: 'ERROR'});
      wsConnection.close();
    }

    return wsConnection;
  }

  const onSpaceChange = (id) => {
    setSpaceData({...spaceData, id});
    history.push(`/${id}`);
  }

  const onSpaceTabChange = (key) => {
    contextData.setActiveTab(key);
  }
  
  const toggleMocking = (checked) => {
    const space = {
      ...contextData,
      Mocking: checked,
    };

    contextData.updateSpace(space);

    setSpaceData({...spaceData, MockingLoading: true});
  }

  const toggleProxy = (checked) => {
    const space = {
      ...contextData,
      Proxying: checked,
    };

    contextData.updateSpace(space);

    setSpaceData({...spaceData, ProxyingLoading: true});
  }

  const handleProxyURLChange = (event) => {
    const proxyUrl = event.target.value;
    setSpaceData({...spaceData, ProxyURL: proxyUrl});
  }

  const genURLContainer = (spaceData) => {
    return (
      <Paragraph ref={urlRef} copyable>
        {SpaceService.buildInterceptURL(spaceData.id)}
      </Paragraph>
    );
  }

  const SpaceHeader = () => {
    return (
      <div>
        <Descriptions size="small" column={3}>
          <Descriptions.Item label={<Tooltip title="Change space id from here">Space</Tooltip>}>
            <Search placeholder="Space Id" defaultValue={spaceData.id} size="small"
              onSearch={(id) => onSpaceChange(id)} enterButton="Set" />
          </Descriptions.Item>

          <Descriptions.Item label={<Tooltip title="Send any HTTP request to this URL">URL</Tooltip>}>
            {genURLContainer(spaceData)}
          </Descriptions.Item>
          
          {/* <Descriptions.Item>
            <Tooltip title="Simulate Dummy HTTP Request">
              <Button type="dashed" size="small"> Simulate Request </Button>
            </Tooltip>
          </Descriptions.Item> */}

          {/* <Descriptions.Item label="Radio">
            <Radio.Group defaultValue="Interception" buttonStyle="solid">
              <Radio.Button value="Interception">Interception</Radio.Button>
              <Radio.Button value="Mocking">Mocking</Radio.Button>
              <Radio.Button value="Proxy">Proxy</Radio.Button>
            </Radio.Group>
            <Input placeholder="Proxy URL" allowClear value={spaceData.proxyUrl || ''} onKeyDown={handleProxyURLChange}
                disabled={!spaceData.proxyEnabled} />
          </Descriptions.Item> */}
        </Descriptions>


        <Descriptions size="small" column={3}>
          <Descriptions.Item label={<Tooltip title="By default interception will catch requests">Interception</Tooltip>}>
            <Switch checkedChildren="ON" unCheckedChildren="OFF" />
          </Descriptions.Item>

          <Descriptions.Item label={<Tooltip title="Mocking will enable mock rules to work">Mocking</Tooltip>}>
            <Switch checkedChildren="ON" unCheckedChildren="OFF" loading={spaceData.MockingLoading}
              checked={spaceData.Mocking} onChange={toggleMocking} />
          </Descriptions.Item>

          <Descriptions.Item label={<Tooltip title="Proxy will send all requests to specified url, and catches request and response">Proxying</Tooltip>}>
            <AntSpace>
              <Switch checkedChildren="ON" unCheckedChildren="OFF" loading={spaceData.ProxyingLoading}
                defaultChecked={spaceData.Proxying} onChange={toggleProxy}/>

              <Input placeholder="Proxy URL" allowClear value={spaceData.ProxyURL} onKeyDown={handleProxyURLChange}
                disabled={!spaceData.Proxying} />
            </AntSpace>
          </Descriptions.Item>
        </Descriptions>
      </div>
    )
  }

  const RequestsContainer = () => {
    return (
      <div>
        <br />
        {/* spaceData: { JSON.stringify(spaceData, null, '\t') }  */}
        <br/>
        { spaceRequests.length > 0 ? <SpaceRequestList requests={spaceRequests}/> : <Empty /> }
      </div>
    )
  }

  return (
    <div>
      <PageHeader
        title={<Tooltip title="This is your space id" placement="bottom">{spaceData.id}</Tooltip>}
        subTitle={ contextData.status === 'CONNECTED' ? <Badge status="processing" text={contextData.status} /> : <Badge status="warning" text={contextData.status} /> }
        extra={[
          <Tooltip title="Simulate an HTTP Request">
            <Button key="simulate_request" onClick={() => Simulator.send(spaceData.id)}> <CloudSyncOutlined /> Simulate Request </Button>
          </Tooltip>,
          <Tooltip title="Refresh the page">
            <Button key="refresh" onClick={() => window.location.reload(false)}> <RedoOutlined /> Refresh</Button>
          </Tooltip>,
          <Tooltip title="Export data in JSON format">
            <Button key="export" type="primary" disabled> <ExportOutlined /> Export</Button>
          </Tooltip>,
        ]}
        footer={
          <Tabs activeKey={contextData.activeSpaceTab} onChange={onSpaceTabChange} tabBarExtraContent={<Tooltip title="Clear Requests"> <Button type="link" danger onClick={() => setSpaceRequests([])}> <DeleteOutlined /> Clear </Button> </Tooltip>}>
            <TabPane key="requests" tab={[<ApiOutlined />, "Requests", <Badge count={spaceRequests.length} showZero={true} offset={[5]} style={{ backgroundColor: '#108ee9' }} />]}>
              <RequestsContainer />
            </TabPane>
            <TabPane key="mock_rules" tab={[<ProfileOutlined />, "Mock Rules", <Badge count={contextData.MockRules.length} showZero={true} offset={[5]} style={{ backgroundColor: '#52c41a' }} />]}>
              <MockRule />
            </TabPane>

            <TabPane key="saved_requests" tab="Saved Requests">
              <Result icon={<SmileOutlined />} title="Coming Soon..."
                extra={<Button type="primary">Subscribe to Email</Button>}/>
            </TabPane>
            <TabPane key="api_specs" tab="API Specs">
              <Result icon={<SmileOutlined />} title="Coming Soon..."
                extra={<Button type="primary">Subscribe to Email</Button>}/>
            </TabPane>
            <TabPane key="terminal" tab="Terminal">
              <Result icon={<SmileOutlined />} title="Coming Soon..."
                extra={<Button type="primary">Subscribe to Email</Button>}/>
            </TabPane>
          </Tabs>
        }
      >
        {/* contextData: { JSON.stringify(contextData) } */}
        <SpaceHeader />
      </PageHeader>
    </div>
  )
}

export default Space;