class DataService {

  static _getBackendHost() {
    let apiHost;

    if(process.env.REACT_APP_API_HOST) {
      // If ENV is present, then use it
      apiHost = process.env.REACT_APP_API_HOST
    } else {
      const frontendHost = window.location.host;
      if( frontendHost.startsWith('localhost') || frontendHost.startsWith('127.0.0.1') || frontendHost.startsWith('0.0.0.0') ) {
        // For Localhost, Use specified host
        apiHost = 'localhost:11000';
      } else {
        apiHost = frontendHost;
      }
    }
    
    return apiHost;
  }

  static buildInterceptURL(spaceId) {
    // TODO: Move this to env
    const proto = process.env.REACT_APP_SECURE_SERVER === 'true' ? 'https' : 'http';
    const host = this._getBackendHost();

    if(host.startsWith('localhost')) {
      // For Localhost, Put spaceId in Path

      return `${proto}://${host}/${spaceId}`;
    } else {
      // For Domains, spaceId will be sub-domain
      
      return `${proto}://${spaceId}.${host}`;
    }
  }

  static async GET(url) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    };

    const response = await fetch(url, options);

    return response.json();
  }

  static async POST(url, payload) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    };

    const response = await fetch(url, options);

    return response.json();
  }

  static async PUT(url, payload) {
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    };

    const response = await fetch(url, options);

    return response.json();
  }

  static async DELETE(url) {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
    };

    const response = await fetch(url, options);

    return response.json();
  }
}

export default DataService;