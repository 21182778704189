import DataService from "./DataService";

class Simulator extends DataService {

  static async send(spaceId) {
    const url = `${this.buildInterceptURL(spaceId)}/from/simulator`;

    const payload = {
      id: 42,
      active: true,
      message: 'This is an example request from simulator',
    };

    this.POST(url, payload);
  }
}

export default Simulator;